import React, { useEffect, useState } from 'react'
// imagenes
import concreto from "../assets/images/concreto.jpg";
import techumbre from "../assets/images/techumbre.jpg";
import vallas from "../assets/images/vallas.jpg";
import revestimiento from "../assets/images/revestimiento.jpg";
import cubiertas from "../assets/images/techo.jpg";
import pintura from "../assets/images/pintura.jpg";
import paneles from "../assets/images/paneles.jpg";
import air from "../assets/images/aire.jpg";
import suelo from "../assets/images/suelo.jpg";
import renovacion from "../assets/images/renovacion.jpg";
import luz from "../assets/images/luz.jpg";
import soldadura from "../assets/images/soldadura.jpg";

// traduccion
import { useTranslation} from "react-i18next";

const CardsService = () => {
    const { t , i18n} = useTranslation('global')
    const [servicios, setServicios] = useState([]);

    useEffect(() => {
        setServicios([
            { title: t("CardsService.concrete"), image: concreto, content: t("CardsService.concreteContent") },
            { title: t("CardsService.electrician"), image: luz, content: t("CardsService.electricianContent") },
            { title: t("CardsService.roofing"), image: techumbre, content: t("CardsService.roofingContent") },
            { title: t("CardsService.fences"), image: vallas, content: t("CardsService.fencesContent") },
            { title: t("CardsService.Siding"), image: revestimiento, content: t("CardsService.SidingContent") },
            { title: t("CardsService.decks"), image: cubiertas, content: t("CardsService.decksContent") },
            { title: t("CardsService.paint"), image: pintura, content: t("CardsService.paintContent") },
            { title: t("CardsService.drywall"), image: paneles, content: t("CardsService.drywallContent") },
            { title: t("CardsService.air"), image: air, content: t("CardsService.airContent") },
            { title: t("CardsService.tiles"), image: suelo, content: t("CardsService.tilesContent") },
            { title: t("CardsService.general"), image: renovacion, content: t("CardsService.generalContent") },
            { title: t("CardsService.welding"), image: soldadura, content: t("CardsService.weldingContent") }
        ]);
    }, [i18n.language]); // Dependencia en el idioma
    
    return (
        <section className="servicios">

            <h1 className="title-service">{t("CardsService.title")}</h1>
            <div className="line"></div>

            <div className="cards-service">

                {servicios.map((servicio, i) => {
                    return (
                        <div key={i} className="card-service">
                            <div className="image-service">
                                <img src={servicio.image} alt={servicio.title} />
                            </div>
                            <div className="content">
                                <h2>{servicio.title}</h2>
                                <div className="line"></div>
                                <p>{servicio.content}</p>
                            </div>
                        </div>
                    );
                })
                }


            </div>
        </section>
    )
}

export default CardsService

