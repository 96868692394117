import React from "react";

import emailjs from "@emailjs/browser";

// traduccion
import { useTranslation } from "react-i18next";
// alertas lindas
import Swal from "sweetalert2";
import Seo from "../components/Seo";

const Contact = () => {

    const { t } = useTranslation('global')

    const formRef = React.createRef();
    const nameRef = React.createRef();
    const emailRef = React.createRef();
    const messageRef = React.createRef();

    const validateForm = () => {
        let send = true;
        const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        const isValidEmailValue = this.emailRef && this.emailRef.current?.value && !emailPattern.test(this.emailRef.current.value);
        const isValidName = this.nameRef && !this.nameRef.current?.value;
        const isValidEmail = this.emailRef && !this.emailRef.current?.value;
        const isValidMessage = this.messageRef && !this.messageRef.current?.value;
        if (isValidName) {
            Swal.fire({
                icon: 'error',
                text: 'The name is required, please fill in the field '
            });
            return send = false;
        } else if (isValidEmail) {
            Swal.fire({
                icon: 'error',
                text: 'The email is required, please fill in the field '
            });
            return send = false;
        } else if (isValidEmailValue) {
            Swal.fire({
                icon: 'error',
                text: 'Please enter a valid email address. '
            });
            return send = false;
        } else if (isValidMessage) {
            Swal.fire({
                icon: 'error',
                text: 'The message is required, please fill in the field '
            });
            return send = false;
        }

        return send;

    }




    const SendEmail = (event) => {
        event.preventDefault();

        if (validateForm()) {
            const serviceId = process.env.REACT_APP_SEVICE_ID;
            const templateId = process.env.REACT_APP_TEMPLATE_ID;
            const apikey = process.env.REACT_APP_API_KEY;

            emailjs.sendForm(serviceId, templateId, this.formRef.current, apikey)
                .then(response => {
                    console.log(response);
                    // limpiar formulario
                    event.target.reset();
                    Swal.fire({
                        icon: 'success',
                        text: 'Your email has been successfully sent',
                        showConfirmButton: false,
                        timer: 1500
                    });
                })
                .catch(error => {
                    console.error(error);
                    // Mostrar mensaje de error
                    Swal.fire({
                        icon: 'error',
                        text: 'There was an error sending your email',
                        showConfirmButton: true
                    });
                });

        }
    }
    return (
        <>
        <Seo title={`Contact USABUILDERS`}/>

        <section className="content-contact">
            <div className="container-contact">
                <div className="row header">
                    <h1 className="contact-title">{t("header.contact")}</h1>
                    <h3 className="contact-subtitle">{t("contact.subtitle")}</h3>
                </div>
                <div className="row body">
                    <form className="form-contact" ref={formRef} onSubmit={SendEmail}>
                        <ul>

                            <li>
                                <div className="left">
                                    <label htmlFor="name">{t("contact.name")}</label>
                                    <input type="text" className="data-contact" name="name" placeholder="John"
                                        ref={nameRef} required pattern="[A-Za-záéíóúüñ' ]+" />
                                </div>
                            </li>

                            <li>
                                <div>
                                    <label htmlFor="email">Email <span className="req">*</span></label>
                                    <input type="email" className="data-contact" name="email"
                                     placeholder="john@gmail.com"
                                        ref={emailRef} required />
                                </div>
                            </li>
                            <li><div className="divider"></div></li>
                            <li>
                                <label htmlFor="message">{t("contact.message")}</label>
                                <textarea cols="46" rows="3" name="message" ref={messageRef} required></textarea>
                            </li>

                            <li>
                                <input className="btn btn-submit" type="submit" value={t("contact.submit")} />
                            </li>

                        </ul>
                    </form>
                </div>
            </div>

            <div className="direct-contact-container">

                <ul className="contact-list">
                    <li className="list-item">
                        {/* <div className="city"> */}
                        <i className='bx bx-location-plus' ></i>
                        {/* <div className="place space"> */}
                        <span className="contact-text space">Hampton Rd, Virginia</span>
                        {/* </div> */}
                        {/* </div> */}
                    </li>

                    <li className="list-item">
                        <i className='bx bx-phone'></i>
                        <span className="contact-text space">
                            +1 (757) 749-9898
                        </span>
                    </li>

                    <li className="list-item">
                        <i className='bx bx-envelope'></i>
                        <span className="contact-text space">
                            usabuilders2020@gmail.com
                        </span>
                    </li>

                </ul>
            </div>
        </section>
        </>
    )
}

export default Contact