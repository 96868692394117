import React from 'react'
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Seo = ({title}) => {
    const { t } = useTranslation('global')

  return (
    <Helmet>
        <title>{`${title} - ${t("home.title")}`}</title>
        <meta name="description" 
        content={t("home.description")}
        data-rh="true"
        />
        <link rel="canonical" href={window.location.href} />
        
        <meta property="og:title" content={`${t("home.title")} in Virginia`} />
        <meta property="og:description" 
        content={t("home.description")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="product" />
    </Helmet>
  )
}

export default Seo
