
import { HelmetProvider } from 'react-helmet-async';
import './assets/css/App.css';
import Router from './Router';


function App() {
  return (
    <div className="App">
      <HelmetProvider>
      <Router/>
      </HelmetProvider>
    </div>
  );
}

export default App;
