import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

// traduccion
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// importar los fichero de los idiomas
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';

// inicializar traducciones
i18n
.use(initReactI18next) // conectar i18n con React
.init({
  resources:{
    es:{
      global: global_es,
    },

    en:{
      global :  global_en,
    }
  },
  lng:"en", // indicar el idioma por default
  fallbackLng: "en", // idioma de reserva
  interpolation: {
    escapeValue: false // no escapar valores HTML
  },


});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
