import React from 'react'
import paisaje from '../assets/images/paisaje.jpeg';

// componentes
import CardsService from "../components/CardsService";
// import Comments from "../components/Comments";
// import Works from "../components/Works";

// traducciones
import { useTranslation } from "react-i18next";
import Seo from '../components/Seo';

const Home = () => {
    const { t } = useTranslation('global')

  return (
    <>
    <Seo title={`USABUILDERS`}/>
    <main className="home">
    <section className="section-1">
        <img className="lazyloaded" src={paisaje} alt="portada" />
        <div className="loaded-phrase">
            <h1 className="title">{t("home.title")}</h1>
        </div>
    </section>

    <CardsService />
    {/* <Works /> */}
    {/* <Comments /> */}

</main>
</>

  )
}

export default Home