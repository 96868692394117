import { useState } from "react";
import logo from '../assets/images/logo.png';
import { NavLink } from "react-router-dom";
// importar traducciones
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import React from 'react'
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Header = () => {
    const { t } = useTranslation('global')
    const location = useLocation()
    const isHomePage = location.pathname === '/' || location.pathname === '/usabuilders';
    const positionHeader = isHomePage ? 'header' : 'header-out';
    const textColor = isHomePage ? 'LinkColorWhite' : 'LinkColorBlack';

    const [clase, setClase] = useState("menu-ul")
    const [hamburgerShow, setHamburgerShow] = useState("hamburger")
    const [closeHamburger, setCloseHamburger] = useState("close-hamburger")
    const [buttonsIdioma, setButtonsIdioma] = useState("content-buttons-idioma")
    const [flechaIdiomas, setFlechaIdiomas] = useState("bx bx-chevron-down")

    const show = () => {
        setClase(prevClase => prevClase === "menu-ul" ? "menu-ul turn-on-menu" : "menu-ul");
        setHamburgerShow(prevHamburgerShow => prevHamburgerShow === "hamburger" ? "hamburger hamburger-hidden" : "hamburger");
        setCloseHamburger(prevCloseHamburger => prevCloseHamburger === "close-hamburger" ? "close-hamburger close-hamburger-show" : "close-hamburger");
    };

    const close = () => {
        setClase(prevClase => prevClase === "menu-ul turn-on-menu" ? "menu-ul" : "menu-ul turn-on-menu");
        setHamburgerShow(prevHamburgerShow => prevHamburgerShow === "hamburger hamburger-hidden" ? "hamburger" : "hamburger hamburger-hidden");
        setCloseHamburger(prevCloseHamburger => prevCloseHamburger === "close-hamburger close-hamburger-show" ? "close-hamburger" : "close-hamburger close-hamburger-show");
        setButtonsIdioma(prevButtonsIdioma => prevButtonsIdioma === "content-buttons-idioma show-idiomas" ? "content-buttons-idioma" : "content-buttons-idioma");
        setFlechaIdiomas(prevFlechaIdiomas => prevFlechaIdiomas === "bx bx-chevron-down bx-rotate-180" ? "bx bx-chevron-down" : "bx bx-chevron-down");
    };

    const idioma = () => {
        setButtonsIdioma(prevButtonsIdioma => prevButtonsIdioma === "content-buttons-idioma" ? "content-buttons-idioma show-idiomas" : "content-buttons-idioma");
        setFlechaIdiomas(prevFlechaIdiomas => prevFlechaIdiomas === "bx bx-chevron-down" ? "bx bx-chevron-down bx-rotate-180" : "bx bx-chevron-down");
    };

  return (
    <header id={`${positionHeader}`}>
    <div className="center">
        {/* logo  */}
        <NavLink to="/">
            <div id="logo" >
                <img src={logo} className="app-logo" alt="logotipo" />
            </div>
        </NavLink>

        {/* menu  */}
        <nav id="menu">

            <div className={hamburgerShow} onClick={show}>
                <i className='bx bx-menu'></i>

            </div>
            <div className={closeHamburger} onClick={close}>
                <i className='bx bx-x'></i>
            </div>


            <ul className={clase}>
                <li>
                    <NavLink activeClassName="active" className={`${textColor}`}
                to="/usabuilders" onClick={close}
                >{t('header.home')}</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" className={`${textColor}`}
                to="/about" onClick={close}
                >{t('header.about')}</NavLink>
                </li>
                <li>
                    <NavLink activeClassName="active" className={`${textColor}`}
                to="/contact" onClick={close}
                >{t('header.contact')}</NavLink>
                </li>

                <li>
                    <div className="idioma" onClick={idioma}>{t('header.language')} 
                        <i className={flechaIdiomas}></i></div>
                        <div className={buttonsIdioma}>
                        <button className="button-idioma" onClick={() => { i18n.changeLanguage("es"); idioma(); close(); } }>
                            {t('header.es')}
                            </button>
                        <button className="button-idioma" onClick={() => { i18n.changeLanguage("en"); idioma(); close(); }}>
                            {t('header.en')}
                            </button>
                    </div>
                   
                </li>
            </ul>
        </nav>

        {/* limpiar flotados */}
        <div className="clearfix"></div>

    </div>
</header>
  )
}

export default Header