import React from 'react'

// importar traducciones
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";


const Footer = () => {
    const { t } = useTranslation('global')

  return (
    <footer >
    <div className="footer">
        <div className="footer-contact">
            <h3>{t("header.contact")}</h3>
            <div className="des-contact">
                <p>Email: usabuilders2020@gmail.com</p>
                <p>{t("contact.phone")}: +1 (757) 749-9898</p>
            </div>
        </div>
        <div className="footer-links">
            <h3>{t("contact.links")}</h3>
            <ul>
                <li><Link to="/usabuilders">{t("header.home")}</Link></li>
                <li><Link to="/about">{t("header.about")}</Link></li>
                <li><Link to="/contact">{t("header.contact")}</Link></li>
            </ul>
        </div>
        <div className="footer-social">
            <h3>{t("contact.follow")}</h3>
            <div className="rounded-social-buttons">
                <a className="social-button facebook" 
                href="https://www.facebook.com/profile.php?id=100063784968620" target="_blank" rel="noopener noreferrer">
                <i className='bx bxl-facebook'></i>
                </a>
                <a className="social-button whatsapp"
                 href="https://wa.me/17577499898" target="_blank" rel="noopener noreferrer">
                <i className='bx bxl-whatsapp'></i>
                </a>
                <a className="social-button instagram"
                 href="https://www.instagram.com/usabuildersllc?igsh=aDFsc3p0NDAyOTBu" target="_blank" rel="noopener noreferrer">
                <i className='bx bxl-instagram'></i>
                </a>
            </div>
        </div>
    </div>
    <div className="copyright-area">
        <div className="copyright-text">
            <p>&copy; 2024 USABUILDERSLLC</p>
        </div>
        <div className="elaborado">
            <p>Desing @Sofiaggb</p>
        </div>
    </div>
</footer>
  )
}

export default Footer